import Api from "./Api";
import CONST from "../const/control_panel/common";

export default {
  /**
   * message Insert
   * @param {*} form
   * @returns object
   */
  async register(form) {
    return Api().post("api/v1/events", form);
  },

  register_file(form, id) {
    return Api().post("api/v1/files/events/" + `${id}`, form);
  },

  /**
   * All message list
   * @returns object
   */
  list(pagination, searchQuery) {
    return Api().get(
      "api/v1/events?desc=created_at&page=" +
        `${pagination.pageNo}` +
        "&per=" +
        `${CONST.PAGINATION.page_count}` +
        searchQuery
    );
  },
  listNum(num) {
    return Api().get(
      "api/v1/events?where=status@公開&desc=created_at&per=" + `${num}`
    );
  },

  /**
   * Show the currect message
   * @returns object
   */
  show(id) {
    return Api().get("api/v1/events/" + `${id}`);
  },
  /**
   * Edit the currect message
   * @returns object
   */
  async edit(id) {
    return Api().get("api/v1/events/" + `${id}`);
  },

  /**
   * message Update
   * @param {*} form ,id
   * @returns object
   */
  async update(form, id) {
    return Api().put("api/v1/events/" + `${id}`, form);
  },
  update_file(form, message_id, file_id) {
    return Api().put(
      "api/v1/files/events/" + `${message_id}` + "/" + `${file_id}`,
      form
    );
  },
  getFile(id) {
    return Api().get("api/v1/files/events/" + `${id}`);
  },

  /**
   * delete the currect message
   * @returns boolean
   */
  delete(id) {
    return Api().delete("api/v1/events/" + `${id}`);
  },

  async filter(pagination, status) {
    return Api().get(
      "api/v1/events?" +
        "&desc=updated_at&page=" +
        `${pagination.pageNo}` +
        "&per=" +
        `${CONST.PAGINATION.page_count}` +
        "&where=status@" +
        `${status}`
    );
  },

  async search(pagination, searchTerms) {
    return Api().get(
      "api/v1/events?" +
        "&desc=updated_at&page=" +
        `${pagination.pageNo}` +
        "&per=" +
        `${CONST.PAGINATION.page_count}` +
        searchTerms
    );
  },
  async inpuSearch(pagination, searchTerms) {
    return Api().get(
      "api/v1/events?" +
        "&desc=updated_at&page=" +
        `${pagination.pageNo}` +
        "&per=" +
        `${CONST.PAGINATION.page_count}` +
        "&keyword=title,content,capacity,contact,place@" +
        `${searchTerms}`
    );
  },

  getEntry(id) {
    return Api().get("api/v1/events/" + `${id}` + "/entry");
  },

  updateEntry(eventId, entryId, status) {
    return Api().put(
      "api/v1/events/" + `${eventId}` + "/entry/" + `${entryId}`,
      status
    );
  },

  entryPost(form, id) {
    return Api().post("api/v1/events/" + `${id}` + "/entry", form);
  },
};
