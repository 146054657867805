<template>
<div id="all_wrap">	
  <main>
    <section>
    <div class="contentout_wrap m_t100">
	<div class="content_wrap">
		<div class="et_wrap">
			<div class="title_wrap">
				<h2 class="title">エントランス</h2>						
			</div>
			<nav class="et_nav">
				<ul>
					<li>
            <!-- 管理画面トップURL確定後追記 -->
            <router-link :to="{name: 'Dashboard'}">
							<span>管理画面 </span>
            </router-link>
            <router-link :to="{ name: 'Front top'}">
							<span>一般ページ </span>
            </router-link>
					</li>
				</ul>				
			</nav>							
		</div><!--et_wrap-->
	</div><!--content_wrap-->																
</div><!--contentoutwrap-->	
</section>
  </main>
</div>
  <!--/component_main-->
</template>

<script>
import $ from 'jquery';


export default {
  data() {
    return {
    };
  },
  components: {},
  created() {
    console.log(this.$User)
    this.phoneMatch();
    if(this.$User.is_admin == 0){
				this.$router.push({ name: 'Front 404' });
			}
  },
  mounted() {
    document.body.className = 'entrance';
    $(function(){
					$('#js-loader').delay(300).fadeOut(600);
					$(window).scrollTop(0);
				});
  },
  methods: {
    phoneMatch: function () {
      var ua = navigator.userAgent
      if (ua.indexOf('iPhone') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
        this.phone = true;
        document.body.id = 'sp_style';
      }
      else {
        this.phone = false;
        document.body.id = 'pc_style';
      }
    },
  },
};
</script>

<style scoped>
label {
  margin-right: 100px;
}
.form-group,
.btn-primary {
  margin-top: 20px;
}
</style>
<style lang="scss">
/* 外部CSSスタイルシートを追加する */
@import "../assets/entrance/sass/style-entrance.scss";
</style>