<template>
  <!--component_footer-->
  <div class="copyright">
    <span>©TAKAYAMA&nbsp;CO.,LTD.</span>
  </div>
  <!--/component_footer-->
</template>

<script>
// @ is an alias to /src
export default {
  name: "Footer",
  components: {},
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>
