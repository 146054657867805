<template>
    <!--component_header-->
      <header id="g_header" v-if="phone">
        <div class="layer">
          <div class="left_area"> 
            <router-link :to="{ name: 'Front top' }"><img src="@/assets/front_component/images/logo/logo01.png" alt="めぐるーむ" style="height: 55px; width: 60px;"></router-link>
            <h1 class="logo_wrap">
              <router-link :to="{ name: 'Front top' }"><img src="@/assets/front_component/images/logo/logo02.png" alt="めぐるーむ"></router-link>
            </h1>
          </div>
          <!-- <div class="logo_wrap">
            <img src="@/assets/front_component/images/logo/logo02.png" alt="TAKAYAMA">
          </div> -->
        </div>
      </header>
      <header id="g_header" v-else>
        <div class="content_wrap">
          <div class="left_area">
            <h1 class="logo_wrap">
              <router-link :to="{ name: 'Front top' }"><img src="@/assets/front_component/images/logo/logo01.png" alt="めぐるーむ"></router-link>
            </h1>
          </div>
        </div><!-- .content_wrap  -->
      </header><!-- #g_header  -->
    <!--/component_header-->
  
  </template>
  
  <script>
  export default {
    name: "Header",
    components: {},
    data() {
      return {
        phone: '',
      };
    },
    created() {
    this.phoneMatch();
    },
    methods: {
      phoneMatch: function () {
      var ua = navigator.userAgent;
      if (
        ua.indexOf("iPhone") > 0 ||
        (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
      ) {
        this.phone = true;
        document.body.id = "sp_style";
        // document.body.id = "pc_style";

      } else {
        this.phone = false;
        document.body.id = "pc_style";
      }
    },
    }
  };
  </script>