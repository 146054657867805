export default {
    LIST: {
      title: "SDGs 個人目標",
  
      sub_title: "SDGs 個人目標一覧",
  
      breadcrumb: {
        dashboard: "ダッシュボード ",
  
        sdgs: "SDGs 個人目標",
      },
      grid: {
        title: "タイトル",

        content: "本文",
        ateseki: "宛先",
        department: "部門",

        created_by: "作成者",
  
        updated_by: "編集者",
  
        created_date: "投稿日",
  
        published_date: "公開日",
  
        status: "申請状況",
      },
      button: {
        create: "新規投稿",
  
        update: "編集",
  
        show: "表示",
  
        delete: "アーカイブ",
  
        search: "検索",
      },
      status: {
        release: "公開",
  
        application: "申請中",

        rejected: "却下",

        approved: "承認済",

        draft: "下書き",
  
        archive: "アーカイブ",
  
        un_select: "未選択",
  
        select_all: "すべて",
  
        has_been_selected: "が選択済みです",
      },
      search: {
        user: "ユーザー",
  
        category: "カテゴリ",
  
        status: "ステータス",
  
         created_date: "投稿日",

        release_date: "公開日",
  
        published_start: "開始日",
  
        published_end: "終了日",
  
        search_results: "検索結果：",
  
        no_results: "検索結果はありません",
      },
      delete: {
        title: "確認",
  
        description: "アーカイブに移動しますか？",
  
        execution: "実行",
  
        cancel: "キャンセル",
      },
      pagination_url: "sdgs",
      search_validation: {
        start_date_format:
          "開始日付には年月日を入力ください。（例：2021/10/28）。",
  
        end_date_format: "終了日付には年月日を入力ください。（例：2021/10/28）。",
  
        start_date_limit: "開始日には終了日以前の日付をご利用ください。",
  
        end_date_limit: "終了日には開始日以降の日付をご利用ください。",
      },
    },
    SHOW: {
      title: "SDGs 個人目標",
  
      sub_title: "SDGs 個人目標詳細",
  
      breadcrumb: {
        dashboard: "ダッシュボード ",
  
        sdgs: "SDGs 個人目標",
  
        sdgs_details: "詳細",
      },
      grid: {
        title: "タイトル",
  
        content: "本文",
  
        ateseki: "宛先",
  
        related_document: "関連資料",
  
        upload_file: "アップロードファイル",
  
        no_related_materials: "関連資料なし",
  
        public_information: "公開情報",
  
        public_status: "公開ステータス",
  
        category: "カテゴリ",
  
        release_date: "公開日付",
  
        article_registration: "記事登録",
  
        registration_date: "登録日",
  
        auther: "作成者",
  
        last_update: "最終更新",
  
        updated_date: "更新日",
  
        department: "部門",
  
        tag: "タグ ",
      },
      button: {
        create: "新規投稿",
  
        update: "編集",
  
        show: "表示",
  
        delete: "アーカイブ",
  
        search: "検索",
      },
      status: {
        release: "公開",
  
        application: "申請中",
  
        draft: "下書き",

        rejected: "却下",

        approved: "承認済",
  
        archive: "アーカイブ",
  
        un_select: "未選択",
  
        select_all: "すべて",
  
        has_been_selected: "が選択済みです",
      },
      search: {
        user: "ユーザー",
  
        category: "カテゴリ",
  
        status: "ステータス",
  
        release_date: "公開日",
  
        published_start: "開始日",

        created_date: "投稿日",
  
        published_end: "終了日",
  
        search_results: "検索結果：",
  
        no_results: "検索結果はありません",
      },
      delete: {
        title: "確認",
  
        description: "アーカイブに移動しますか？",
  
        execution: "実行",
  
        cancel: "キャンセル",
      },
      pagination_url: "sdgs",

      sdgs_list: "SDGs 個人目標一覧",
    },
    fiscalYears: [
      {
        fiscalValue: 50,
        year: 2023,
      },
      {
        fiscalValue: 51,
        year: 2024
      },
      {
        fiscalValue: 52,
        year: 2025
      },
      {
        fiscalValue: 53,
        year: 2026
      }
    ]
  };
  