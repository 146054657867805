<template>
  <!--component_header--->
  <Header />
  <!--/component_header--->

  <!--component_main-->
  <router-view />
  <!--/component_main-->

  <!--component_footer--->
  <Footer />
  <!--/component_footer--->
</template>

<script>
// @ is an alias to /src
import Header from "@/layout/front_panel/Header.vue";
import Footer from "@/layout/front_panel/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>
<style lang="scss">
/* 外部CSSスタイルシートを追加する */
@import "../../assets/front_component/scss/style-pc.scss";
</style>
<style>
/* 外部CSSスタイルシートを追加する */
@import "../../assets/front_component/fontawesome/all.css";
@import "../../assets/front_component/css/magnific-popup.css";
@import "../../assets/front_component/css/slick.css";
@import "../../assets/front_component/css/fullcalendar.css";
@import "../../assets/front_component/js/select2/select2.min.css";
@import "../../assets/front_component/css/scroll-hint.css";
</style>

