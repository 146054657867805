import Api from "./Api";
import CONST from "../const/control_panel/common";

export default {
  /**
   * User Insert
   * @param {*} form
   * @returns object
   */
  /**

     * User Insert 

     * @param {*} form 

     * @returns object

     */

  async register(form) {
    // return Api().post("/user/store", form);

    return Api().post("api/v1/users", form);
  },

  /**

 * All user list

 * @returns object

 */

  async list() {
    return Api().get("api/v1/users");

    // return Api().get("/user/get");
  },

  async listGroups(id) {
    console.log(location);

    console.log(navigator.userAgent);

    return Api().get("api/v1/users?where=group@" + `${id}`);
  },

  /**

 * Show the currect user

 * @returns object

 */

  async show(id) {
    return await Api().get("api/v1/users/" + `${id}`);
  },

  /**

 * delete the currect user

 * @returns boolean

 */

  async delete(id) {
    return Api().delete("api/v1/user/" + `${id}` + "/delete");
  },

  async top() {
    return Api().get("/sanctum/csrf-cookie");
  },

  async login(form) {
    return Api().post("api/login", form);
  },

  async logout() {
    return Api().post("api/logout");
  },

  update(form, id) {
    return Api().put("api/v1/users/" + `${id}`, form);
  },

  group_list() {
    return Api().get("api/v1/users/groups");

    // return Api().get("/user/get");
  },

  post_group(form) {
    // return Api().post("/user/store", form);

    return Api().post("api/v1/users/groups", form);
  },

  post_group_user(form, id) {
    // return Api().post("/user/store", form);

    return Api().post("api/v1/users/groups/" + `${id}`, form);
  },

  post_tag(form) {
    // return Api().post("/user/store", form);

    return Api().post("api/v1/tags", form);
  },

  delete_tag(id) {
    return Api().delete("api/v1/tags/" + `${id}`);
  },

  showfile(id, key, value) {
    return Api().get(
      "api/v1/files/users/" + `${id}` + "?where=" + `${key}` + "@" + `${value}`
    );
  },

  search_group(name) {
    return Api().get("api/v1/users/groups?where=name@" + `${name}`);

    // return Api().get("/user/get");
  },

  get_group(id) {
    return Api().get(
      "api/v1/users/groups/" + `${id}` + "?whereNull=leaving_date"
    );

    // return Api().get("/user/get");
  },
  get_group_byId(id) {
    return Api().get(
      "api/v2/users/groups/" + `${id}` + "?whereNull=leaving_date"
    );
  },
  // news投稿の承認宛先用に内部統括部の執行役員部長を取得
  get_news_approval() {
    return Api().get("api/v1/users/get_news_approval");
  },
  showGroupUsers(id) {
    return Api().get(`api/v2/groups_users/${id}`);
  },
  getGroupAllUsers(id) {
    return Api().get(`api/v2/group/users/${id}`);
  },
  userPostedSdgsReport(msgId) {
    var url = `api/v2/user/sdgsreport/${msgId}`;
    return Api().get(url);
  },
};
