import $ from "jquery";
import Ideas from "../../apis/Ideas";
import User from "../../apis/User";
import idea from "../../const/control_panel/idea";


export const getAllIdeas = ({ commit, dispatch, rootState }, { pageParam, type }) => {

    dispatch('common/setSpinnerStatus', true, { root: true })

    var allIdeas;

    return Ideas.list(pageParam, rootState.idea.searchQuery).then(async response => {

            if (response != '') {

                allIdeas = response.data.data;

                for (const idea of allIdeas) {

                    console.log("first");
                    if (idea.target_ids != null) {

                        idea.target_user = [];

                        for (const targetId of idea.target_ids) {


                            await User.show(targetId).then(async response => {

                                if (response != '') {

                                    await idea.target_user.push(response.data.data);

                                }

                            });

                        }

                    }


                }

                console.log("lsat");
                console.log(allIdeas);

                commit('SET_ALL_IDEAS', allIdeas);

                commit('SET_PAGINATION', response.data);

                rootState.common.pagination = response.data;

            }


        })
        .catch((error) => {


        })

    .finally(() => {

        return dispatch('common/setSpinnerStatus', false, { root: true });

    });

}

export const viewIdeas = ({ commit, dispatch, rootState }, id) => {

    dispatch('common/setSpinnerStatus', true, { root: true })

    return Ideas.show(id).then(response => {

        commit('SET_IDEAS', response.data);

    })

    .catch(() => {})

    .finally(() => {
        dispatch('common/setSpinnerStatus', false, { root: true })
    });
}


export const deleteIdeas = ({ commit, dispatch, rootState }, id) => {

    dispatch('common/setSpinnerStatus', true, { root: true });


    return Ideas.delete(id).then(

        (response) => {

            if (response != '') {

                commit('DELETE_NEWS', id);

                $(".bulktool").removeClass("open");

                $(".command_wrap_inbox").removeClass("hide");

                $(".modal").removeClass("open");


                return dispatch('common/setSucccessMessageStatus', {

                    status: true,
                    message: "創発が正常に削除されました。",

                }, { root: true });

            }
        },

        (error) => {

            console.log(error);
        }
    )

    .catch(() => {})

    .finally(() => {

        return dispatch('common/setSpinnerStatus', false, { root: true });

    });
}

export const setDeleteId = ({ commit, dispatch, rootState }, id) => {

    commit('SET_DELETE_IDEAS_ID', id);

}


export const setFileterStatus = ({ commit, dispatch, rootState }, { papeParam, status }) => {

    dispatch('common/setSpinnerStatus', true, { root: true })


    let searchStatus = "";

    if (status != "")

        searchStatus = "&where=status@" + `${status}`;

    commit('SET_SEARCH_QUERY', searchStatus);


    return Ideas.filter(papeParam, searchStatus).then(async response => {


        var allIdeas;

        if (response != '') {

            allIdeas = response.data.data;

            for (const idea of allIdeas) {

                if (idea.target_ids != null) {

                    idea.target_user = [];

                    for (const targetId of idea.target_ids) {


                        await User.show(targetId).then(async response => {

                            if (response != '') {

                                await idea.target_user.push(response.data.data);

                            }

                        });

                    }

                }


            }


            commit('SET_ALL_IDEAS', allIdeas);

            commit('SET_PAGINATION', response.data);

            dispatch('common/setSpinnerStatus', false, { root: true })

            rootState.common.pagination = response.data;

        }


    })


    .catch(() => {})

    .finally(() => {

        setTimeout(() => {

            dispatch('common/setSpinnerStatus', false, { root: true })

        }, 10000);
    });

}

export const getSearchData = ({ commit, dispatch, rootState }, { papeParam, searchTerms }) => {

    dispatch('common/setSpinnerStatus', true, { root: true })

    let statusQuery = "";

    if (searchTerms.status != "")

        statusQuery = "&where=status@" + `${searchTerms.status}`;

    let dateQuery = "";

    if (searchTerms.published_start != "") {

        dateQuery = "&datetime=created_at@gte," + `${searchTerms.published_start}T00:00:00`;

    }

    if (searchTerms.published_end != "") {

        dateQuery = "&datetime=created_at@lte," + `${searchTerms.published_end}T23:59:59`;

    }

    if (searchTerms.published_end != "" && searchTerms.published_start != "") {

        dateQuery = "&datetime=created_at@between," + `${searchTerms.published_start}T00:00:00/` + `${searchTerms.published_end}T23:59:59`;

    }

    commit('SET_SEARCH_QUERY', statusQuery + dateQuery);


    return Ideas.search(papeParam, statusQuery + dateQuery).then(async response => {


            var allIdeas;

            if (response != '') {

                allIdeas = response.data.data;

                for (const idea of allIdeas) {

                    if (idea.target_ids != null) {

                        idea.target_user = [];

                        for (const targetId of idea.target_ids) {


                            await User.show(targetId).then(async response => {

                                if (response != '') {

                                    await idea.target_user.push(response.data.data);

                                }

                            });

                        }

                    }

                }

                commit('SET_ALL_IDEAS', allIdeas);

                commit('SET_PAGINATION', response.data);

                dispatch('common/setSpinnerStatus', false, { root: true })

                rootState.common.pagination = response.data;

            }


        })
        .catch(() => {})

    .finally(() => {

        setTimeout(() => {

            dispatch('common/setSpinnerStatus', false, { root: true })

        }, 10000);
    });

}




export const inputSearchData = ({ commit, dispatch, rootState }, { pageParam, searchTerms }) => {
    dispatch('common/setSpinnerStatus', true, { root: true })

    commit('SET_SEARCH_QUERY', "&keyword=title@" + `${searchTerms}`);

    return Ideas.inpuSearch(pageParam, searchTerms).then(async response => {

            var allIdeas;

            if (response != '') {

                allIdeas = response.data.data;

                for (const idea of allIdeas) {

                    if (idea.target_ids != null) {

                        idea.target_user = [];

                        for (const targetId of idea.target_ids) {


                            await User.show(targetId).then(async response => {

                                if (response != '') {

                                    await idea.target_user.push(response.data.data);

                                }

                            });

                        }

                    }


                }


                commit('SET_ALL_IDEAS', allIdeas);

                commit('SET_PAGINATION', response.data);

                dispatch('common/setSpinnerStatus', false, { root: true })

                rootState.common.pagination = response.data;

            }

        })
        .catch(() => {})

    .finally(() => {

        setTimeout(() => {

            dispatch('common/setSpinnerStatus', false, { root: true })

        }, 10000);
    });


}