<template>
  <!--component_footer-->
  <footer id="footer">
    <div class="cloud_wrap"></div>
    <div class="city_wrap"></div>
    <div class="car_wrap"></div>
    <div class="car_wrap02"></div>
    <div class="img_wrap">
      <div class="wood_wrap">
        <img
          src="@/assets/front_component/images/assets/footer01.png"
          alt="木"
        />
      </div>
      <div class="signboard_wrap">
        <img
          src="@/assets/front_component/images/assets/footer02.png"
          alt="©TAKAYAMA CO.,LTD."
        />
      </div>
    </div>
  </footer>

  <div v-if="phone == true">
    <div v-if="isTopPage == 'Front top'" id="pagetop" class="pagetop_wrap">
      <a href="#">
        <span>上に<br />戻る</span>
      </a>
    </div>
  </div>
  <div v-else>
    <div v-if="isTopPage == 'Front top'" id="pagetop" class="pagetop_wrap">
      <a href="#">
        <span>上に<br />戻る</span>
      </a>
    </div>
  </div>

  <!--/component_footer-->
</template>

<script>
// @ is an alias to /src
export default {
  name: "Footer",
  data() {
    return {
      phone: "",
      isTopPage: "Front top",
    };
  },
  components: {},
  created() {
    setInterval(() => {
      this.isTopPage = this.$nextpage;
    }, 200);
  },
  watch: {
    $nextpage(val) {
      this.$nextpage = val;
    },
    // $route(to, from) {
    //   this.isTopPage = this.$nextpage;
    // },
  },

  methods: {
    phoneMatch: function () {
      var ua = navigator.userAgent;
      if (
        ua.indexOf("iPhone") > 0 ||
        (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
      ) {
        this.phone = true;
        document.body.id = "sp_style";
      } else {
        this.phone = false;
        document.body.id = "pc_style";
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>
