import Api from "./Api";

export default {
  getNotificationByMessageId(message_id) {
    return Api().get("api/v1/auth/notifications/messages/" + `${message_id}`);
  },
  notificationRead(id) {
    return Api().put("api/v1/auth/notifications/" + `${id}`);
  },
  notification() {
    return Api().get("api/v1/auth/notifications?filter=unreadonly");
  },
  notificationCount(type) {
    return Api().get("api/v1/auth/notification_count?type=" + `${type}`);
  },
  thanksNotificationCount(type) {
    return Api().get("api/v1/auth/thanks_notification_count?type=" + `${type}`);
  },
  
};
