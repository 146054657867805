export const setSpinnerStatus = ({ commit }, status) => {

    commit('SET_SPINNER_STATUS', status);

}


export const setSucccessMessageStatus = ({ commit }, { status, message }) => {

    commit('SET_SUCCESS_MESSAGE_STATUS', { status, message });

}


export const setPaginationscreen = ({ commit }, pageName) => {

    commit('SET_PAGINATION_SCREEN', pageName);

}

