import Calendar from "../../apis/Calendar";
import $ from "jquery";
import moment from "moment";

export const getAllSchedules = ({ commit, dispatch, rootState }) => {
  dispatch("common/setSpinnerStatus", true, { root: true });
  return Calendar.list()
    .then(async (response) => {
      if (response != "") {
        await commit("SET_ALL_SCHEDULES", response.data);
        $("#calendar").fullCalendar("refetchEvents"); //再描画
      }
    })
    .catch((error) => {})
    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const createSchedules = (
  { commit, dispatch, rootState },
  createSchedules
) => {
  dispatch("common/setSpinnerStatus", true, { root: true });
  return Calendar.createSchedules(createSchedules)
    .then(async (response) => {
      if (response != "") {
        await commit("SET_ALL_SCHEDULE_LIST", response.data);
        $("#calendar").fullCalendar("refetchEvents"); //再描画
      }
    })
    .catch((error) => {})
    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};
export const deleteSchedules = ({ commit, dispatch, rootState }, id) => {
  dispatch("common/setSpinnerStatus", true, { root: true });
  return Calendar.deleteSchedules(id)
    .then(async (response) => {
      if (response != "") {
        commit("DELETE_SCHEDULE", id);
        $("#calendar").fullCalendar("refetchEvents"); //再描画
      }
    })
    .catch((error) => {})
    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const updateSchedules = (
  { commit, dispatch, rootState },
  { id, updateSchedules }
) => {
  dispatch("common/setSpinnerStatus", true, { root: true });
  return Calendar.updateSchedules(id, updateSchedules)
    .then(async (response) => {
      if (response != "") {
        await commit("SET_ALL_SCHEDULE_LIST", response.data);
        $("#calendar").fullCalendar("refetchEvents"); //再描画
      }
    })
    .catch((error) => {})
    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};
