import Event from "../../apis/admin_Event";
import router from "../../router/index";
import $ from "jquery";
import File from "../../apis/File";
import moment from "moment";
import User from "../../apis/User";

export const eventRegister = (
  { commit, dispatch, rootState },
  { event, file }
) => {
  dispatch("common/setSpinnerStatus", true, { root: true });
};

export const getAllEvent = (
  { commit, dispatch, rootState },
  { papeParam, type }
) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  return Event.list(papeParam, rootState.event.searchQuery)
    .then((response) => {
      if (response != "") {
        commit("SET_ALL_EVENT", response.data);
        commit("SET_PAGINATION", response.data);
        rootState.common.pagination = response.data;
      }
    })
    .catch((error) => {})

    .finally(() => {
      return dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const createEvent = (
  { commit, dispatch, rootState },
  { event, files, eyeCatch }
) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  return Event.register(event)
    .then(async (response) => {
      var createdId = response.data.data.id;

      commit("SET_CREATED_EVENT", response.data);

      if (eyeCatch != "") {
        await dispatch(
          "file/uploadEventEyeCatch",
          {
            file: eyeCatch,
            id: createdId,
          },
          { root: true }
        );
      }

      if (files.length > 0) {
        dispatch("common/setSpinnerStatus", true, { root: true });

        return dispatch(
          "file/uploadEventFile",
          {
            files: files,
            id: createdId,
          },
          { root: true }
        );
      } else {
        return router.push({
          name: "Event Show",
          params: { id: createdId },
        });
      }
    })
    .catch(() => {})

    .finally(() => {
      dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const viewEvent = ({ commit, dispatch, rootState }, id) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  return Event.show(id)
    .then(async (response) => {
      let events = response.data;

      await User.show(events.data.create_user_id).then(async (response) => {
        if (response != "") {
          events.data.create_user = response.data.data;

          await User.show(events.data.update_user_id).then(async (response) => {
            if (response != "") {
              events.data.update_user = response.data.data;

              commit("SET_EVENT", events);
            }
          });
        }
      });
    })

    .catch(() => {})

    .finally(() => {
      return dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const updateEvent = (
  { commit, dispatch, rootState },
  {
    event,
    id,
    files,
    deleteEventsId,
    updateEventsFiles,
    eyeCatch,
    isUpdate,
    eyeCatchId,
    isEyeCatchDelete,
  }
) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  event.published_at = moment(String(event.published_at)).format(
    "YYYY-MM-DD HH:mm:ss"
  );


  event.implementation_datetime.start_datetime = moment(
    String(event.implementation_datetime.start_datetime)
  ).format("YYYY-MM-DD HH:mm:ss");

  event.implementation_datetime.end_datetime = moment(
    String(event.implementation_datetime.end_datetime)
  ).format("YYYY-MM-DD HH:mm:ss");

  event.reception_period.start_datetime = moment(
    String(event.reception_period.start_datetime)
  ).format("YYYY-MM-DD HH:mm:ss");

  event.reception_period.end_datetime = moment(
    String(event.reception_period.start_datetime)
  ).format("YYYY-MM-DD HH:mm:ss");

  return Event.update(event, id)
    .then(async (response) => {
      commit("SET_UPDATED_EVENT", response.data);

      if (isEyeCatchDelete && eyeCatchId != "") {
        await dispatch(
          "file/deleteEventEyeCatch",
          {
            modelId: id,
            id: eyeCatchId,
          },
          { root: true }
        );
      }

      if (eyeCatch != "") {
        if (isUpdate) {
          await dispatch(
            "file/updateEventEyeCatch",
            {
              modelId: id,
              id: eyeCatchId,
              file: eyeCatch,
              isDelete: true,
            },
            { root: true }
          );
        } else {
          await dispatch(
            "file/uploadEventEyeCatch",
            {
              file: eyeCatch,
              id: id,
            },
            { root: true }
          );
        }
      }

      if (deleteEventsId.length > 0) {
        dispatch("common/setSpinnerStatus", true, { root: true });
        for (let index = 0; index < deleteEventsId.length; index++) {
          dispatch(
            "file/delteFileEvent",
            {
              id: id,
              fileId: deleteEventsId[index],
            },
            { root: true }
          );
        }
        dispatch("common/setSpinnerStatus", false, { root: true });
      }

      if (updateEventsFiles.length > 0) {
        dispatch("common/setSpinnerStatus", true, { root: true });
        for (let index = 0; index < updateEventsFiles.length; index++) {
          dispatch(
            "file/updateFileEvent",
            {
              file: {
                display_name: updateEventsFiles[index].display_name,
                key: "events",
              },
              eventId: id,
              fileId: updateEventsFiles[index].id,
            },
            { root: true }
          );
        }
        dispatch("common/setSpinnerStatus", false, { root: true });
      }

      if (files.length > 0) {
        dispatch(
          "file/uploadEventFile",
          {
            files: files,
            id: id,
          },
          { root: true }
        );
      } else {
        router.push({
          name: "Event Show",
          params: { id: id },
        });
      }
    })
    .catch(() => {})

    .finally(() => {
      return dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const deleteEvent = ({ commit, dispatch, rootState }, id) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  return Event.delete(id)
    .then(
      (response) => {
        if (response != "") {
          commit("DELETE_EVENT", id);

          $(".bulktool").removeClass("open");

          $(".command_wrap_inbox").removeClass("hide");

          $(".modal").removeClass("open");

          return dispatch(
            "common/setSucccessMessageStatus",
            {
              status: true,
              message: "イベントが正常に削除されました。",
            },
            { root: true }
          );
        }
      },

      (error) => {
        console.log(error);
      }
    )

    .catch(() => {})

    .finally(() => {
      return dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const setDeleteId = ({ commit, dispatch, rootState }, id) => {
  commit("SET_DELETE_EVENT_ID", id);
};

export const setFileterStatus = (
  { commit, dispatch, rootState },
  { papeParam, status }
) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  commit("SET_SEARCH_QUERY", "&where=status@" + `${status}`);

  return Event.filter(papeParam, status)
    .then((response) => {
      if (response != "") {
        commit("SET_ALL_EVENT", response.data);
        commit("SET_PAGINATION", response.data);

        rootState.common.pagination = response.data;
      }
    })
    .catch(() => {})

    .finally(() => {
      return dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const getSearchData = (
  { commit, dispatch, rootState },
  { papeParam, searchTerms, keyword }
) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  let statusQuery = "&where=status@" + `${searchTerms.status}`;

  let dateQuery = "";
  let keySearch = "";

  if (keyword != "") {
    keySearch = "&keyword=title,content,capacity,contact,place@" + `${keyword}`;
  }

  if (searchTerms.published_start != "") {
    dateQuery =
      "&datetime=created_at@gte," + `${searchTerms.published_start}T00:00:00`;
  }

  if (searchTerms.published_end != "") {
    dateQuery =
      "&datetime=created_at@lte," + `${searchTerms.published_end}T23:59:59`;
  }

  if (searchTerms.published_end != "" && searchTerms.published_start != "") {
    dateQuery =
      "&datetime=created_at@between," +
      `${searchTerms.published_start}T00:00:00/` +
      `${searchTerms.published_end}T23:59:59`;
  }

  commit("SET_SEARCH_QUERY", statusQuery + dateQuery + keySearch);

  return Event.search(papeParam, statusQuery + dateQuery + keySearch)
    .then((response) => {
      if (response != "") {
        commit("SET_ALL_EVENT", response.data);
        commit("SET_PAGINATION", response.data);

        rootState.common.pagination = response.data;
      }
    })
    .catch(() => {})

    .finally(() => {
      return dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const inputSearchData = (
  { commit, dispatch, rootState },
  { pageParam, searchTerms, searchData }
) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  let statusSearch = "";

  if (searchData.status != "") {
    statusSearch = "&where=status@" + `${searchData.status}`;
  }

  commit(
    "SET_SEARCH_QUERY",
    "&keyword=title,content,capacity,contact,place@" +
      `${searchTerms}` +
      `${statusSearch}`
  );

  return Event.inpuSearch(pageParam, searchTerms + statusSearch)
    .then((response) => {
      if (response != "") {
        commit("SET_ALL_EVENT", response.data);
        commit("SET_PAGINATION", response.data);

        rootState.common.pagination = response.data;
      }
    })
    .catch(() => {})

    .finally(() => {
      return dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const getEntry = ({ commit, dispatch, rootState }, id) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  return Event.getEntry(id)
    .then(async (response) => {
      let entry = response.data;

      entry.data.forEach((element) => {
        User.show(element.from_id).then(async (response) => {
          if (response != "") {
            element.from_user = response.data.data;
          }
        });
      });

      await commit("SET_ALL_ENTRY", entry);
    })

    .catch(() => {})

    .finally(() => {
      return dispatch("common/setSpinnerStatus", false, { root: true });
    });
};

export const updateEntry = (
  { commit, dispatch, rootState },
  { eventId, entryId, status }
) => {
  dispatch("common/setSpinnerStatus", true, { root: true });

  return Event.updateEntry(eventId, entryId, status)
    .then(async (response) => {
      await Event.getEntry(eventId).then(async (response) => {
        let entry = response.data;

        for (const element of entry.data) {
          await User.show(element.from_id).then(async (response) => {
            if (response != "") {
              element.from_user = response.data.data;
            }
          });
        }

        await commit("SET_UPDATE_ALL_ENTRY", entry);
      });
    })
    .catch(() => {})
    .finally(() => {
      return dispatch("common/setSpinnerStatus", false, { root: true });
    });
};
