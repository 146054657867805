export const SET_SPINNER_STATUS = (state, status) => {

    state.spinner = status;

}


export const SET_SUCCESS_MESSAGE_STATUS = (state, { status, message }) => {

    state.successMessage = status;

    state.successMessageLable = message;

}



export const SET_PAGINATION_SCREEN = (state, pageName) => {

    state.paginationScreen = pageName;

}

