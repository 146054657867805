export const getAllFiles = (state) => {
    return state.allFiles;
}

export const getViewFile = (state) => {
    return state.viewFile;
}

export const getFileStoreage = (state) => {
    return state.fileStoreage;
}