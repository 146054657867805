

export const SET_ALL_SDGS = (state, sdgs) => {

    state.allSdgs = sdgs;

}
export const SET_PAGINATION = (state, sdgs) => {

    state.pagination = sdgs;
}


export const SET_SDGS = (state, sdgs) => {

    state.showSdgs = sdgs.data;

}

export const SET_DELETE_SDGS_ID = (state, id) => {

    state.sdgsDeleteId = id;

}

export const DELETE_NEWS = (state, id) => {

    state.allSdgs = state.allSdgs.filter(item => {

        return item.id != id;

    })
}
export const DELETE_SDGS = (state, id) => {
    state.allSdgs = state.allSdgs.filter((item) => {
      return item.id != id;
    });
  };
export const SET_SEARCH_QUERY = (state, query) => {

    state.searchQuery = query;

}