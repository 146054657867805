<template>
  <!--component_header--->
  <Header />
  <!--/component_header--->

  <!--component_main-->
  <main>
    <router-view />
  </main>
  <!--/component_main-->

  <!--component_footer--->
  <Footer />
  <!--/component_footer--->
</template>

<script>
// @ is an alias to /src
import Header from "@/layout/front_panel/Header.vue";
import Footer from "@/layout/front_panel/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>

