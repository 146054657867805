import moment from "moment";
import User from "../../apis/User";
import store from "../index"

export const SET_ALL_CHALLENGE = (state, challenge) => {

    state.allChallenge = challenge;

}

export const SET_PAGINATION = (state, challenge) => {

    state.pagination = challenge;

}



export const SET_CHALLENGE = (state, challenge) => {

    state.showChallenge = challenge;

}


export const DELETE_CHALLENGE = (state, id) => {

    state.allChallenge = state.allChallenge.filter(item => {

        return item.id != id;

    })
}

export const SET_DELETE_CHALLENGE_ID = (state, id) => {

    state.challengeDeleteId = id;

}

export const SET_SEARCH_QUERY = (state, query) => {

    state.searchQuery = query;

}