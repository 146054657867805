

export const SET_ALL_IDEAS = (state, ideas) => {

    state.allIdeas = ideas;

}


export const SET_PAGINATION = (state, idea) => {

    state.pagination = idea;
}

export const SET_IDEAS = (state, ideas) => {

    state.showIdeas = ideas.data;

}

export const SET_DELETE_IDEAS_ID = (state, id) => {

    state.ideaDeleteId = id;

}

export const DELETE_NEWS = (state, id) => {

    state.allIdeas = state.allIdeas.filter(item => {

        return item.id != id;

    })
}

export const SET_SEARCH_QUERY = (state, query) => {
    
    state.searchQuery = query;

}