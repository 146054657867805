export default {
    allEvent: [],
    showEvent: {},
    createdEvent: {},
    deleteId: '',
    pagination: {},
    searchQuery: "&where=status@下書き,公開",
    allEntry : [],
    updateAllEntry : [],

}