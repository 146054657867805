import Api from "./Api";
import CONST from "../const/control_panel/common";

export default {
  /**
   * User Insert
   * @param {*} form
   * @returns object
   */
  /**

     * User Insert 

     * @param {*} form 

     * @returns object

     */

  async register(form) {
    return Api().post("api/v1/users", form);
  },

  /**
   * All user list
   * @returns object
   */
  async list(pagination, searchQuery) {
    return Api().get(
      "api/v1/users" +
        "?desc=updated_at&page=" +
        `${pagination.pageNo}` +
        "&per=" +
        `${CONST.PAGINATION.page_count}` +
        searchQuery
    );
  },

  /**
   * Show the currect user
   * @returns object
   */
  async show(id) {
    return await Api().get("api/v1/users/" + `${id}`);
  },

  /**
   * delete the currect user
   * @returns boolean
   */
  async delete(id) {
    return Api().delete("api/v1/users/" + `${id}`);
  },

  async top() {
    return Api().get("/sanctum/csrf-cookie");
  },

  async login(form) {
    return Api().post("api/login", form);
  },

  async logout() {
    return Api().post("api/logout");
  },

  async update(form, id) {
    return Api().put("api/v1/users/" + `${id}`, form);
  },

  async search(pagination, searchTerms) {
    return Api().get(
      "api/v1/users" +
        "?desc=updated_at&page=" +
        `${pagination.pageNo}` +
        "&per=" +
        `${CONST.PAGINATION.page_count}` +
        `${searchTerms}`
    );
  },
  async inpuSearch(pagination, searchTerms) {
    return Api().get(
      "api/v1/users" +
        "?desc=updated_at&page=" +
        `${pagination.pageNo}` +
        "&per=" +
        `${CONST.PAGINATION.page_count}` +
        "&keyword=last_name,first_name,user_code,email,phone@" +
        `${searchTerms}`
    );
  },


  group_list() {
    return Api().get("api/v1/users/groups");
    // return Api().get("/user/get");
  },

  post_group(form) {
    // return Api().post("/user/store", form);
    return Api().post("api/v1/users/groups", form);
  },

  post_group_user(form) {
    // return Api().post("/user/store", form);
    return Api().post("api/v1/users/groups/11", form);
  },

  post_tag(form) {
    // return Api().post("/user/store", form);
    return Api().post("api/v1/tags", form);
  },

  delete_tag(id) {
    return Api().delete("api/v1/tags/" + `${id}`);
  },
  
  updateOrders(data){
    return Api().post("api/v2/users/order/change", data);
  }
};
