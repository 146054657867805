/**
 * For all common libraries import
 */
import { createApp } from "vue";
import App from "./App.vue";
import jQuery from "jquery";
import Api from "./apis/Api";

import router from "./router";
import CKEditor from "@ckeditor/ckeditor5-vue";
import moment from "moment-timezone";
import "es6-promise/auto";
import store from "./store/index";
moment.tz.setDefault("Asia/Tokyo");
// register jw pagination component globally
import JwPagination from "jw-vue-pagination";
import VueGtag from "vue-gtag";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const app = createApp(App).use(jQuery).use(router).use(store).use(CKEditor).use(VueSweetalert2);

app.component("jw-pagination", JwPagination);

router.beforeEach((to, from, next) => {
  // isPublic でない場合(=認証が必要な場合)
  if (to.matched.some((record) => !record.meta.isPublic)) {
    //Authチェック
    Api()
      .get("api/v1/auth")
      .then(function (response) {
        var Users = response.data;
        const User = Users.data;
        app.use(VueGtag, {
          config: {
             id: "G-J5VCD1654P",
             params: {
              user_id: User.last_name+User.first_name
            }
          }
        });

        //プロフィール画像の有無
        Api()
          .get("api/v1/files/users/" + User.id + "?where=key@users")
          .then(
            (response) => {
              if (response != "") {
                let path = response.data.data[0].storage_file_name;
                let fileApiPass = "/api/v1/file/" + path;
                Api()
                  .get(fileApiPass, { responseType: "arraybuffer" })
                  .then(
                    (response) => {
                      let blob = new Blob([response.data], {
                        type: response.headers["content-type"],
                      });
                      let url = window.URL || window.webkitURL;
                      let src = url.createObjectURL(blob);
                      User.imgpath = src;
                    },
                    (error) => {
                      User.imgpath = null;
                    }
                  );
              }
            },
            (error) => {
              console.log(error);
              console.log(error.response.data.errors);
            }
          )
          .catch(() => {});

        //親グループの取得
        if (User.group) {
          if (User.group.parent_id) {
            Api()
              .get("api/v1/users/groups")
              .then(
                (response) => {
                  if (response != "") {
                    for (let i = 0; i < response.data.data.length; i++) {
                      if (User.group.parent_id == response.data.data[i].id) {
                        User.group.parent_name = response.data.data[i].name;
                        break;
                      }
                    }
                  }
                },
                (error) => {}
              )
              .catch(() => {});
          }
        }

        app.config.globalProperties.$User = User;
        app.config.globalProperties.$nextpage = to.name;
        if (to.fullPath.match(/admin/)) {
          if (!User.is_admin) {
            next({ name: "Front top" });
          }
        }
        next();
      })
      .catch(function (error) {
        next({ path: "/login", query: { redirect: to.fullPath } });
      });
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  var ua = navigator.userAgent;

  if (
    window.name != "any" &&
    from.name != "Control messagecreate" &&
    from.name != "Control eventcreate" &&
    from.name != "Control eventedit"
  ) {
    if (
      ua.indexOf("iPhone") > 0 ||
      (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0)
    ) {
      // console.log("null");
    } else {
      location.reload();
    }

    // location.reload();

    window.name = "any";
  } else {
    window.name = "";
  }
});

app.mount("#app");
