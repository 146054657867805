export default {
  loginUser: JSON.parse(localStorage.getItem("user")) || [],

  userFullName: JSON.parse(localStorage.getItem("user"))
    ? JSON.parse(localStorage.getItem("user")).first_name +
      " " +
      JSON.parse(localStorage.getItem("user")).last_name
    : "",

  user: {},

  searchQuery: "",

  allUsers: [],

  pagination: {},

  viewUser: {},

  validationError: {},

  userDeleteId: "",

  groups: [],

  role: [],
};
