<template>
  <div id="all_wrap">
    <Header />
    <Sidebar />

    <div class="mvcontent">
      <router-view />
      <!--maincontent-->
      <Footer />
    </div>
    <!--mvcontent-->
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/layout/control_panel/Header.vue";
import Sidebar from "@/layout/control_panel/Sidebar.vue";
import Footer from "@/layout/control_panel/Footer.vue";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import "jquery-ui-dist/jquery-ui.min.css";

export default {
  name: "App",
  components: {
    Header,
    Sidebar,
    Footer,
  },
  mounted() {
    /**
     * For screen tooltip rendering jquery
     */
    $(".tooltip").tooltip({
      show: false,
      hide: false,
      tooltipClass: "tipinfo",
    });
    $(".tip-top").tooltip({
      position: {
        my: "center top",
        at: "center top",
      },
    });
    $(".tip-bottom").tooltip({
      position: {
        my: "center bottom",
        at: "center top",
      },
    });
    $(".tip-left").tooltip({
      position: {
        my: "left top",
        at: "right top",
      },
    });
    $(".tip-right").tooltip({
      position: {
        my: "right top",
        at: "left top",
      },
    });
  },
};
</script>

<style scoped >
/* 外部CSSスタイルシートを追加する */
/* @import "../../assets/control_panel/scss/main.scss"; */
</style>


