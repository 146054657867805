export const getNeswItems = (state) => {
    return state.allNews;
}

export const displayNews = (state) => {
    return state.showNews;
}

export const getType = (state) => {

    return state.showNews.type ? 'お知らせ' : '';

}