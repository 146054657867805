import Api from "./Api";

export default {
  /**
   * User Insert
   * @param {*} form
   * @returns object
   */
  async create(form) {
    return Api().post("api/v1/users/groups", form);
  },

  /**
   * All user list
   * @returns object
   */
  async list() {
    return Api().get("api/v1/users/groups");
  },

  /**
   * Show the currect user
   * @returns object
   */
  async show(id) {
    return await Api().get("api/v1/users/groups/" + `${id}`);
  },

  /**
   * message Update
   * @param {*} form ,id
   * @returns object
   */
  async update(form, id) {
    return Api().put("api/v1/users/groups/" + `${id}`, form);
  },

  /**
   * delete the currect user
   * @returns boolean
   */
  async delete(id) {
    return Api().delete("api/v1/user/" + `${id}` + "/delete");
  },

  /**
   * delete the currect user
   * @returns boolean
   */
  async insertGroupUser(id, data) {
    return Api().post("api/v1/users/groups/" + `${id}`, data);
  },

  /**
   * delete the currect user
   * @returns boolean
   */
  async updateGroupUser(id, userId, data) {
    return Api().put(
      "api/v1/users/groups/" + `${id}` + "/" + `${userId}`,
      data
    );
  },
  async deleteGroupUser(id, userId) {
    return Api().delete("api/v1/users/groups/" + `${id}` + "/" + `${userId}`);
  },

  getGroupById(id) {
    return Api().get(
      "api/v1/users/groups/" + `${id}` + "?whereNull=leaving_date"
    );
  },

  getGroupUsersByGroupId(id, type) {
    return Api().get("api/v1/groups/" + `${id}` + "?type=" + type);
  },

  getSearchGroupUsers(id, searchTearms, type) {
    return Api().get(
      "api/v1/group/" +
        `${id}` +
        "/users?searchTerms=" +
        searchTearms +
        "&type=" +
        type
    );
  },

  getAllGroupUsers() {
    return Api().get("api/v1/groups_all_users");
  },

  cgGroupList(groupParentId) {
    return Api().get("api/v1/cs_group_users/" + `${groupParentId}`);
  },

  getGroupDetailByUserID(userId) {
    return Api().get("api/v1/groups_by_user_id/" + `${userId}`);
  },

  getAllList() {
    return Api().get("api/v2/groups");
  },

  getGroupUsers(groupId) {
    return Api().get(`api/v2/users/groups/${groupId}`);
  },
};
