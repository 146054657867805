import Api from "./Api";

export default {
  /**
   * file upload
   * @returns boolean
   */
  async fileUpload(form, id) {
    return Api().post("api/v1/files/messages/" + `${id}`, form);
  },

  /**
   * file upload
   * @returns boolean
   */
  async fileUploadEvent(form, id) {
    return Api().post("api/v1/files/events/" + `${id}`, form);
  },

  /**
   * delete the currect message
   * @returns boolean
   */
  async getFile(id) {
    return Api().get("api/v1/files/messages/" + `${id}`);
  },

  /**
   * delete the currect message
   * @returns boolean
   */
  async getFileEvent(id) {
    return Api().get("api/v1/files/events/" + `${id}`);
  },
  /**
   * delete the currect message
   * @returns boolean
   */
  async updateFile(form, id, fileId) {
    return Api().put(
      "api/v1/files/messages/" + `${id}` + "/" + `${fileId}`,
      form
    );
  },

  /**
   * delete the currect message
   * @returns boolean
   */
  async updateFileEvent(form, id, fileId) {
    console.log(form);
    return Api().put(
      "api/v1/files/events/" + `${id}` + "/" + `${fileId}`,
      form
    );
  },

  /**
   * delete the currect message
   * @returns boolean
   */
  async deleteFile(id, fileId) {
    return Api().delete("api/v1/files/messages/" + `${id}` + "/" + `${fileId}`);
  },

  /**
   * delete the currect event
   * @returns boolean
   */
  async delteFileEvent(id, fileId) {
    return Api().delete("api/v1/files/events/" + `${id}` + "/" + `${fileId}`);
  },

  /**
   * delete the currect message
   * @returns boolean
   */
  async fileStorageName(storageName) {
    return Api().get("api/v1/file/" + `${storageName}`, {
      responseType: "blob",
    });
  },

  /**
   * file upload
   * @returns boolean
   */
  async imageUpload(model, form, id) {
    return Api().post("api/v1/files/" + `${model}` + "/" + `${id}`, form);
  },

  /**
   * delete the currect message
   * @returns boolean
   */
  async getPicture(model, id) {
    return Api().get("api/v1/files/" + `${model}` + "/" + `${id}`);
  },

  /**
   * file upload
   * @returns boolean
   */
  async imageUpdate(model, modelId, form, id) {
    return Api().put(
      "api/v1/files/" + `${model}` + "/" + `${modelId}` + "/" + `${id}`,
      form
    );
  },
  /**
   * file upload
   * @returns boolean
   */
  async imageDelete(model, modelId, id) {
    return Api().delete(
      "api/v1/files/" + `${model}` + "/" + `${modelId}` + "/" + `${id}`
    );
  },
};
